import z from 'zod';
import * as storage from './session-storage';

const storageKey = 'profile';
const profileSchema = z.object({
  tags: z.array(z.string()),
  sourceModule: z.string(),
  profileCreatedOn: z.string(),
});

export type Profile = z.infer<typeof profileSchema>;

type DemoAnswer = {
  question_name: string;
  answers: any;
};

type Expectation = {
  question_name: string;
  answers: string[];
};

type Matcher = {
  tag: string;
  expects: Expectation[];
};
const answeredDemographicsTag = 'answeredDemo';
const exampleMatchers: Matcher[] = [
  {
    tag: 'insights',
    expects: [
      {
        question_name: 'age',
        answers: ['A-16-17', 'A-18-24'],
      },
      {
        question_name: 'years employed',
        answers: ['A-less than 1 month', 'A-1-12 months'],
      },
      {
        question_name: 'plays',
        answers: ['A-No'],
      },
    ],
  },
];

function generateProfileIds(
  demographics: DemoAnswer[],
  matchers: Matcher[],
): string[] {
  const matchedTags: string[] = [answeredDemographicsTag];

  matchers.forEach(matcher => {
    let isMatch = true;

    for (const expect of matcher.expects) {
      const demographic = demographics.find(
        demo => demo.question_name === expect.question_name,
      );

      if (
        !demographic ||
        !demographic.answers.some(answer => expect.answers.includes(answer))
      ) {
        isMatch = false;
        break;
      }
    }

    if (isMatch) {
      matchedTags.push(matcher.tag);
    }
  });

  return matchedTags;
}

function saveProfile(userId: string, profile: Profile): void {
  const currentData = storage.getData(storageKey);
  currentData[userId] = profile;
  storage.saveData(storageKey, currentData);
}

function createProfile(
  userId: string,
  module: string,
  demographics: any,
): string[] {
  const profileIds = generateProfileIds(demographics, exampleMatchers);
  const profile = {
    tags: profileIds,
    sourceModule: module,
    profileCreatedOn: new Date().getTime().toString(),
  } as Profile;
  saveProfile(userId, profile);
  return profileIds;
}

function getProfile(userId: string): Profile | undefined {
  const data = storage.getData(storageKey);
  if (data[userId]) {
    try {
      return profileSchema.parse(data[userId]);
    } catch (error) {
      console.error(`Error validating profile data: ${error}`);
      return undefined;
    }
  }

  return undefined;
}

export const Profile = {
  createProfile,
  getProfile,
};
